*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Roboto';

  background-color: #ffdf00;
}
